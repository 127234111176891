
import { Technician } from "@/store/modules";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class TechnicianChangeCognitoButton extends Vue {
  @Prop(Technician)
  private record!: Technician;

  @Prop(Boolean)
  loading = false;

  savingText = "Saving";

  showModal = false;

  cognitoID = "";

  saving = false;

  error: string | null = null;

  mounted() {
    this.$nextTick(() => {
      const { cognitoID } = this.record;
      if (cognitoID) {
        this.cognitoID = cognitoID;
      }
    });
  }

  get text() {
    const { cognitoID } = this.record;
    return cognitoID ? "Set cognito id" : "Change cognito id";
  }

  onCloseModal() {
    this.showModal = false;
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  async onSave() {
    this.error = null;
    if (!this.cognitoID) {
      this.error = "Missing cognito id";
    }

    if (this.error) {
      return;
    }

    this.saving = true;
    await Technician.dispatch("updateCognitoId", {
      id: this.record.ID,
      token: this.cognitoID,
    });
    this.error = null;
    if (
      this.$store.state.entities.technician.error &&
      this.$store.state.entities.technician.error.length
    ) {
      this.error =
        this.$store.state.entities.technician.error[0].message.join(" ");
    }

    this.saving = false;
    this.toggleModal();

    this.$emit("on-saved", this.record.ID);
  }

  async forcePasswordReset() {
    try {
      // await MR.dispatch("approve", {
      //   id: this.record.id,
      // });
    } catch (err) {
      console.error("Error occured", err, this);
    }
  }
}

export default TechnicianChangeCognitoButton;
