
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Timesheet, TimesheetFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({})
export class TimesheetList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Timesheet[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", {
    default: () => ({}),
    type: Object,
  })
  columnFilterValueSync!: TimesheetFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "tech_name", label: "Name", filter: false },
    // { key: "start", label: "Start" },
    // { key: "end", label: "End" },
    { key: "timeRange", label: "Time" },
    { key: "durationHours", label: "Duration (Hours)", filter: false },
    { key: "work_type", label: "Type" },
    { key: "taskNumber", label: "Task Number" },
    { key: "mrID", label: "MR" },
    { key: "travel", label: "Travel" },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: TimesheetFilterQueryPayload) {
    console.log("onColumnFilterChange", val);
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: Timesheet) {
    this.$router.push(`/timesheet/${item.id}`);
  }

  setStartDateFilter(e: Event) {
    if (!e.target) {
      return;
    }
    const s = { ...this.columnFilterValueSync };
    s.startDate = (e.target as HTMLInputElement).value;
    this.columnFilterValueSync = s;
  }

  setEndDateFilter(e: Event) {
    if (!e.target) {
      return;
    }

    const s = { ...this.columnFilterValueSync };
    s.endDate = (e.target as HTMLInputElement).value;
    this.columnFilterValueSync = s;
  }

  setTravelFilter(e: Event) {
    if (!e.target) {
      return;
    }

    const s = { ...this.columnFilterValueSync };
    const v = `${(e.target as HTMLInputElement).value}`;
    if (!v) {
      s.travel = null;
    } else {
      s.travel = v;
    }
    // s.travel = (e.target as HTMLInputElement).value;
    this.columnFilterValueSync = s;
  }
}

export default TimesheetList;
