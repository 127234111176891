
import { TableCardLoader } from "@/components/TableCardLoader.vue";
import { DateFormatMixin, HeMixin } from "@/mixins";
import MRList from "@/pages/mr/MRList.vue";
import {
  MR,
  MRAllPayload,
  MRFilterQueryPayload,
  Technician,
} from "@/store/modules";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import MRListHandler from "../../mr/MRListHandler.vue";

@Component({
  components: { MRList, MRListHandler, TableCardLoader },
})
export class TechnicianMRList extends mixins(DateFormatMixin, HeMixin) {
  @Prop(Technician)
  private technician!: Technician;

  // private loading = true;
  colFilter: MRFilterQueryPayload = {};

  // @Watch("colFilter")
  // onColFilterChange(
  //   val: MRFilterQueryPayload | null,
  //   oldVal: MRFilterQueryPayload | null
  // ) {
  //   if (!val || !oldVal) {
  //     return;
  //   }

  //   if (queryFilterHasChange<MRFilterQueryPayload>(val, oldVal)) {
  //     this.$nextTick(() => this.fetchRender());
  //   }
  // }

  // mounted() {
  //   this.$nextTick(() => this.fetchRender());
  // }

  // async fetchRender() {
  //   this.loading = true;
  //   // console.log('findMR')
  //   await Technician.dispatch("findMR", {
  //     id: +this.technician.ID,
  //     ...{ filter: filterEmptyQuery(this.colFilter) }
  //   });
  //   this.loading = false;
  //   // const t = Technician.query().withAllRecursive().whereId(this.technician.ID).get();
  //   // console.log(t);
  // }

  get loading() {
    return !!Technician.store().state.entities.technician.fetching;
  }

  async dispatchFetch(payload: MRAllPayload) {
    await Technician.dispatch("findMR", {
      id: +this.technician.ID,
      ...payload,
    });
    // const f = {...payload};
    // f.filter.
    // await Technician.dispatch("fetchData", f);
  }

  get dataSource() {
    const ID = this.technician.ID;
    return MR.query()
      .withAllRecursive()
      .where("techID", (val: number | null) => val === ID)
      .get();
  }
}

export default TechnicianMRList;
