
import TechnicianMap from "@/pages/location/maps/TechnicianMap.vue";
import PartRequestList from "@/pages/part-request/PartRequestList.vue";
import {
  GeoLocation, PartRequest, TaskSchedule, Technician,
  TechnicianInterface
} from "@/store/modules";
import { LngLatLike } from "mapbox-gl";
import { Component, Prop, Vue } from "vue-property-decorator";
import TimesheetList from "../timesheet/TimesheetList.vue";
import { TechnicianCardInfo } from "./detail/TechnicianCardInfo.vue";
import { TechnicianMRList } from "./detail/TechnicianMRList.vue";
import { TechnicianScheduleList } from "./detail/TechnicianScheduleList.vue";
import TechnicianTimesheetList from './detail/TechnicianTimesheetList.vue';

@Component({
  components: {
    TechnicianCardInfo,
    TechnicianMRList,
    TechnicianMap,
    TimesheetList,
    TechnicianScheduleList,
    PartRequestList,
    TechnicianTimesheetList,
  },
})
export default class TechnicianDetail extends Vue {
  @Prop([String, Number])
  readonly id!: string | number;

  technician: TechnicianInterface | null = null;

  loading = true;

  position?: LngLatLike;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  refreshTech() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    let technician = this.findRecord();
    // await PositionModule.getCurrentPosition();
    // if (PositionModule.position) {
    //   this.position = PositionModule.position;
    // }

    if (!technician) {
      this.loading = true;

      await Technician.dispatch("findOne", { id: +this.id });
      // await GeoLocation.dispatch('byTech', {techId: +this.id, sort: 'time', limit: 1});
      // await Timesheet.dispatch("findByTech", {techId: +this.id})

      technician = this.findRecord();
    }

    await TaskSchedule.dispatch("techSchedule", { techId: +this.id });
    await GeoLocation.dispatch("byTech", {
      techId: +this.id,
      sort: "time",
      limit: 1,
    });
    // await Timesheet.dispatch("findByTech", { techId: +this.id });
    await PartRequest.dispatch("findByTech", { 
      techId: +this.id,
      sort: 'created_at',
      order: 'desc'
    });

    this.technician = this.findRecord();
    // console.info("tech", this.technician);

    if (
      this.technician &&
      this.technician.geoLocations &&
      this.technician.geoLocations.length
    ) {
      this.position = {
        lat: this.technician.geoLocations[0].lat,
        lng: this.technician.geoLocations[0].long,
      };
    }

    this.loading = false;
  }

  // get loading(): boolean {
  //   // return true;
  //   return !!Technician.store().state.entities.mr.fetching;
  // }

  findRecord(): Technician | null {
    return Technician.query()
      .with('geoLocations')
      .with('taskSchedules')
      .with('partRequests')
      .with('partRequests.part')
      // .withAllRecursive()
      .whereId(+this.id)
      .first();
  }

  /*get jobId(): number | null {
    const technician = this.technician;
    return technician && technician.jobID ? technician.jobID : null;
  }*/
}
