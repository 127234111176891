
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import { Technician } from "@/store/modules";
import TechnicianChangeCognitoButton from "./TechnicianChangeCognitoButton.vue";
import AuthRegisterButton from "./AuthRegisterButton.vue";
import ChangePasswordButton from "./ChangePasswordButton.vue";

@Component({
  components: {
    TechnicianChangeCognitoButton,
    AuthRegisterButton,
    ChangePasswordButton
  },
})
export class TechnicianCardInfo extends mixins(DateFormatMixin, HeMixin) {
  @Prop(Technician)
  private technician!: Technician;

  // @Prop({default: () => () => {}})
  // private onSave: (id: number) => void;

  onSave(val: number) {
    this.$emit('on-saved', val);
  }
}

export default TechnicianCardInfo;
