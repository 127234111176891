
import { Component, Watch, Vue } from "vue-property-decorator";
import TechnicianList from "./TechnicianList.vue";
import {
  Technician,
  TechnicianFilterQueryPayload,
  TechnicianAllPayload,
  SortQueryPayload,
} from "@/store/modules";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { CDataTableSortVal } from "@/@types";

@Component({
  components: { TechnicianList },
})
export default class TechnicianListHandler extends Vue {
  limit = 25;
  // loading: boolean = true;
  colFilter: TechnicianFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "ID",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  @Watch("colFilter")
  onColFilterChange(
    val: TechnicianFilterQueryPayload | null,
    oldVal: TechnicianFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<TechnicianFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: TechnicianAllPayload) {
    await Technician.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Technician.query().withAll().get();
    return data;
  }

  get loading(): boolean {
    return !!Technician.store().state.entities.technician.fetching;
  }

  get total() {
    return Technician.store().state.entities.technician.total;
  }

  get numPages(): number {
    const total = Technician.store().state.entities.technician.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  compilePayload(
    merge: Partial<TechnicianAllPayload> = {}
  ): TechnicianAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: filterEmptyQuery(this.colFilter) },
      ...merge,
    };
  }

  /*get pagination(): boolean | AnyObject {
    const total = Technician.store().state.entities.mr.total;
    if(total) {
      console.log('test', Math.min(Math.ceil(total / this.limit), 1) );
      return {
        pages: Math.min(Math.ceil(total / this.limit), 1),
        activePage: 2
      };
    }
    return false;
  }*/
}
