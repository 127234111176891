
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import { MRAllPayload, MRFilterQueryPayload, MR } from "@/store/modules";
import { filterEmptyQuery } from "@/utility";
import { SortQueryPayload } from "@/store/modules";

import Multiselect from "vue-multiselect";
type OptionProps = { text: string; value: number };

@Component({ components: { Multiselect } })
export default class MRMultiDropdown extends Vue {
  filter: MRFilterQueryPayload = {};

  sort: SortQueryPayload = {
    sort: "id",
    order: "asc",
  };

  // @Model("change", { type: Array, default: () => [] })
  value: OptionProps[] = [];

  // @Model("change", { type: Number, required: false })
  mappedTags: MR[] = [];

  options: Array<OptionProps> = [];

  optionIndex: Map<number, MR> = new Map();

  debounceTimeout?: NodeJS.Timeout;

  searchString = "";

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  get loading() {
    return !!MR.store().state.entities.mr.fetching;
  }

  async fetchRender(payload: MRAllPayload) {
    await MR.dispatch("fetchData", payload);
    this.updateOptions();
  }

  updateOptions(): Array<any> {
    const data = MR.query().withAll().get();

    this.options = data.map((mr) => ({
      text: this.MRName(mr),
      value: mr.id,
    }));

    this.optionIndex = new Map(data.map((mr) => [mr.id, mr]));

    return this.options;
  }

  compilePayload(merge: Partial<MRAllPayload> = {}): MRAllPayload {
    return {
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): MRAllPayload["filter"] {
    const f = filterEmptyQuery(this.filter);
    if (this.searchString) {
      const match = /^\#?(?:MR)?\s*\#?\s*(\d+)$/gi.exec(this.searchString);
      if (match && match.length > 1) {
        f["id"] = match[1];
      } else {
        f["customer_name"] = this.searchString;
      }
    }
    return { ...f };
  }

  get availableOptions() {
    return this.options;
    // return this.options.filter((opt) => this.value.indexOf(opt.text) === -1);
  }

  async onSearchChange(query: string) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(async () => {
      // this.seaching = true;
      this.searchString = query;
      await this.fetchRender(this.compilePayload());
      // this.seaching = false;
    }, 1200);
  }

  onSelect(selectedOption: OptionProps, id: number) {
    const { value } = selectedOption;
    const opt = this.optionIndex.get(value);
    if (opt) {
      // handler.change(`${opt.name}`);
      this.mappedTags.push(opt);
      this.$emit("mr-select", this.mappedTags);
    }
  }

  onRemove({ value }: OptionProps, id: number) {
    const i = this.mappedTags.findIndex((t) => t.id === value);
    this.mappedTags.splice(i, 1);
    this.$emit("mr-select", this.mappedTags);
  }

  MRName(mr: MR) {
    return `MR #${mr.id} ${mr.customer_name ? mr.customer_name : ''}`.trim();
  }
}
