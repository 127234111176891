export function formatTaskNumber(val: string) {
  let i = 0;
  const pattern = '###-#-####-#'
  const v = `${val || ''}`.trim().replaceAll(/[^a-zA-Z\d]/g, '');
  return pattern.split('').reduce<string[]>((acc, m) => {
    if (typeof v[i] === 'undefined') {
      return acc;
    }

    if (m === '#') {
      const c = v[i++];

      acc.push(c);
      return acc;
    }

    acc.push(m);
    return acc;
  }, []).join('');
}