
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { TimesheetList } from "./TimesheetList.vue";
import {
  TimesheetFilterQueryPayload,
  TimesheetAllPayload,
  Timesheet,
  Technician,
  MR,
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload } from "@/store/modules";
import TechnicianMultiDropdown from "@/pages/technician/filters/TechnicianMultiDropdown.vue";
import MRMultiDropdown from "@/pages/mr/filters/MRMultiDropdown.vue";

@Component({
  components: { TimesheetList, TechnicianMultiDropdown, MRMultiDropdown },
})
export default class TimesheetListHandler extends Vue {
  limit = 25;

  colFilter: TimesheetFilterQueryPayload = {
    startDate: "",
    endDate: "",
  };

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {
    column: 'created_at', asc: false
  };

  // @Model("tech-select", { type: Array, default: () => [] })
  filterTechs!: Technician[];

  filterMR!: MR[];

  debounceTimeout?: NodeJS.Timeout;

  /**
   * in ms
   */
  debounceTime = 1000;

  @Prop({ type: Boolean, default: () => true })
  itemsPerPageSelect!: boolean;

  @Prop({ type: Boolean, default: () => false })
  disableTopFilters!: boolean;

  @Prop({ type: Boolean, default: () => true })
  enablePagination = true;

  @Prop(Boolean)
  isLoading?: boolean;

  @Prop(Number)
  total?: number;

  @Prop()
  dataSource?: (
    filters: TimesheetAllPayload
  ) => Array<Timesheet> | Array<Timesheet>;

  @Prop()
  dispatchFetch?: (filters: TimesheetAllPayload) => Promise<void>;

  @Watch("colFilter", { deep: true })
  onColFilterChange(
    val: TimesheetFilterQueryPayload | null,
    oldVal: TimesheetFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }
    if (queryFilterHasChange<TimesheetFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  mounted() {
    this.load();
  }

  load(debounce = false) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    if (!debounce) {
      this.$nextTick(() => this.fetchRender(this.compilePayload()));
      return;
    }

    this.debounceTimeout = setTimeout(
      () => this.$nextTick(() => this.fetchRender(this.compilePayload())),
      this.debounceTime
    );
  }

  async fetchRender(payload: TimesheetAllPayload) {
    // console.log('xtest',payload);
    if (this.dispatchFetch) {
      return this.dispatchFetch(payload);
    }
    await Timesheet.dispatch("all", payload);
  }

  get items(): Array<Timesheet> {
    if (this.dataSource) {
      if (typeof this.dataSource === "function") {
        return this.dataSource(this.compilePayload());
      }
      // console.log(this.dataSource);
      return this.dataSource;
    }

    const data = Timesheet.query().orderBy('i').withAll().get();

    return data;
  }

  get loading(): boolean {
    if (typeof this.isLoading !== "undefined") {
      return !!this.isLoading;
    }
    return !!Timesheet.store().state.entities.timesheet.fetching;
  }

  // get total() {
  //   return Timesheet.store().state.entities.timesheet.total;
  // }

  get numPages(): number {
    let total = 0;
    if (typeof this.total === "undefined") {
      total = Timesheet.store().state.entities.timesheet.total;
    } else {
      total = +this.total;
    }
    // const total = Timesheet.store().state.entities.timesheet.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  get totalHours(): number {
    return Timesheet.store().state.entities.timesheet.hours;
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  compilePayload(
    merge: Partial<TimesheetAllPayload> = {}
  ): TimesheetAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): TimesheetAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);
    const timeRange = [];
    if (this.colFilter.startDate || this.colFilter.endDate) {
      timeRange.push(this.colFilter.startDate || "");
      timeRange.push(
        this.colFilter.endDate || new Date(Date.now()).toISOString()
      );
      delete f.startDate;
      delete f.endDate;
    }

    if (timeRange.length) {
      f.timeRange = timeRange.join(",");
    }

    if (this.filterTechs && this.filterTechs.length) {
      f.techID = (this.filterTechs || []).map((tech) => tech.ID).join(",");
    }

    if (this.filterMR && this.filterMR.length) {
      f.mrID = (this.filterMR || []).map((mr) => mr.id).join(",");
    }

    f.voided = 0;

    return { ...f };
  }

  onTechSelect(options: Technician[]) {
    this.filterTechs = options;

    this.load(true);
  }

  onMR(options: MR[]) {
    this.filterMR = options;
    this.load(true);
  }
}
