
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";

@Component
export default class MRStatusFilter extends Vue {
  @PropSync("selected", { default: () => [], type: Array })
  selectedSync!: string[];

  options = [
    { text: "Completed", value: "completed" },
    { text: "Voided", value: "voided" },
    { text: "Hide approved", value: "hide_approve" },
    // { text: "Hide open", value: "hide_open" },
    { text: "Paid only", value: "paid" },
    { text: "Unpaid only", value: "unpaid" },
    { text: "Not on hold", value: "hide_on_hold" },
    // { text: "Signed", value: "signed" },
  ];

  // selected: string[] = [];

  onChange(val: string | null) {
    if (!val) {
      return;
    }
    const s = this.selectedSync;
    let i = -1;
    
    if (val === "paid") {
      i = s.indexOf("unpaid");
    } else if (val === "unpaid") {
      i = s.indexOf("paid");
    }
    if (i !== -1) {
      s.splice(i, 1);
      s.push(val);
    }
  }

  @Watch("selected")
  onSelectChange() {
    // console.log("B", this.selectedSync);
    this.$emit("select", this.selectedSync);
  }
}
