
import { Technician } from "@/store/modules";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class AuthRegisterButton extends Vue {
  @Prop(Technician)
  private record!: Technician;

  @Prop(Boolean)
  loading = false;

  savingText = "Saving";

  showModal = false;

  email = "";
  password = "";
  confirmPassword = "";

  saving = false;

  error: string | null = null;

  get serverError() {
    console.log("serverError", this.$store.state);
    return this.$store.state.entities.technician.error;
  }

  mounted() {
    this.$nextTick(() => {
      const { email, cognitoID } = this.record;
      if (email && !cognitoID) {
        this.email = email;
      }
    });
  }

  get text() {
    const { cognitoID } = this.record;
    return cognitoID ? "Register" : "Add cognito ID";
  }

  onCloseModal() {
    this.showModal = false;
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  async onSave() {
    this.error = null;
    if (!this.email) {
      this.error = "Missing email";
    }

    if (!this.password) {
      this.error = "Missing password";
    }

    if (this.password !== this.confirmPassword) {
      this.error = "Passwords do not match";
    }

    if (this.error) {
      return;
    }

    this.saving = true;

    await Technician.dispatch("registerAndUpdateCognitoId", {
      techId: this.record.ID,
      email: this.email,
      password: this.password,
    });

    this.error = null;
    if (
      this.$store.state.entities.technician.error &&
      this.$store.state.entities.technician.error.length
    ) {
      this.error =
        this.$store.state.entities.technician.error[0].message.join(" ");
    }
    this.saving = false;
    this.toggleModal();

    this.$emit("on-saved", this.record.ID);
  }

  async forcePasswordReset() {
    try {
      // await MR.dispatch("approve", {
      //   id: this.record.id,
      // });
    } catch (err) {
      console.error("Error occured", err, this);
    }
  }
}

export default AuthRegisterButton;
