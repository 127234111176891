
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import {
  Technician,
  MR,
  MRFilterQueryPayload,
  Timesheet,
  TimesheetAllPayload,
} from "@/store/modules";
import MRList from "@/pages/mr/MRList.vue";
import { TableCardLoader } from "@/components/TableCardLoader.vue";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import MRListHandler from "../../mr/MRListHandler.vue";
import TimesheetListHandler from "../../timesheet/TimesheetListHandler.vue";

@Component({
  components: { TableCardLoader, TimesheetListHandler },
})
export class TechnicianTimesheetList extends mixins(DateFormatMixin, HeMixin) {
  @Prop(Technician)
  private technician!: Technician;

  private loading = true;
  colFilter: MRFilterQueryPayload = {};

  // @Watch("colFilter")
  // onColFilterChange(
  //   val: MRFilterQueryPayload | null,
  //   oldVal: MRFilterQueryPayload | null
  // ) {
  //   if (!val || !oldVal) {
  //     return;
  //   }

  //   if (queryFilterHasChange<MRFilterQueryPayload>(val, oldVal)) {
  //     this.$nextTick(() => this.fetchRender());
  //   }
  // }

  // mounted() {
  //   this.$nextTick(() => this.fetchRender());
  // }

  // async fetchRender() {
  //   console.log('a')
  //   this.loading = true;
  //   // console.log('findMR')
  //   await Technician.dispatch("findMR", {
  //     id: +this.technician.ID,
  //     ...{ filter: filterEmptyQuery(this.colFilter) },
  //   });
  //   this.loading = false;
  //   // const t = Technician.query().withAllRecursive().whereId(this.technician.ID).get();
  //   // console.log(t);
  // }

  async dispatchFetch(payload: TimesheetAllPayload) {
    await Timesheet.dispatch("findByTech", {
      techId: +this.technician.ID,
      sort: "created_at",
      order: "desc",
      ...payload,
    });
    // await Technician.dispatch("findMR", {
    //   id: +this.technician.ID,
    //   ...payload,
    // });
  }

  get dataSource() {
    const ID = this.technician.ID;
    return Timesheet.query()
      // .withAllRecursive()
      .where("techID", (val: number | null) => val === ID)
      .get();
  }
}

export default TechnicianTimesheetList;
