
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import { Technician, TechnicianFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({
  components: {},
})
export class TechnicianList extends mixins(DateFormatMixin, HeMixin) {
  @Prop({ default: () => [] })
  private items!: Technician[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: TechnicianFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "firstname", label: "Name" },
    { key: "email", label: "Email" },
    { key: "employee_num", label: "Emp #" },
    { key: "phone", label: "Phone" },
    { key: "cell", label: "Cell" },
    { key: "address", label: "Address" },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: TechnicianFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }
}

export default TechnicianList;
